import React, { useState, useRef, useEffect } from "react";
import Measure from "react-measure";
import { useUserMedia } from "./hooks/useUserMedia";
import { useCardRatio } from "./hooks/useCardRatio";
import { useOffsets } from "./hooks/useOffsets";
import Wrapper from "./components/Wrapper";
import Container from "./components/Container";
import Video from "./components/Video";
import Overlay from "./components/Overlay";
import Canvas from "./components/Canvas";
import Flash from "./components/Flash";
import { Box, Icon, IconButton, Stack } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CameraSwitchIcon from "@mui/icons-material/Cameraswitch";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "environment" },
};

const Camera = ({ onConfirm, style }) => {
  const dpi = window.devicePixelRatio;

  const canvasRef = useRef();
  const videoRef = useRef();

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  // const [aspectRatio, calculateRatio] = useCardRatio(1.574); // default card ratio
  const [aspectRatio, calculateRatio] = useCardRatio(1.333); // 4:3

  useEffect(() => {
    if (mediaStream && videoRef.current) {
      videoRef.current.srcObject = mediaStream;
    }
  }, [mediaStream]);

  function handleResize(contentRect) {
    setContainer({
      height: Math.round(contentRect.bounds.width / aspectRatio),
      width: contentRect.bounds.width,
    });
  }

  function handleCanPlay() {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    videoRef.current.play();
  }

  const capturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const photoDataUrl = canvas.toDataURL("image/jpeg");
      onConfirm(photoDataUrl);
    }
  };

  if (!mediaStream) {
    return null;
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <Wrapper>
          <Container
            ref={measureRef}
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Video ref={videoRef} hidden={!isVideoPlaying} onCanPlay={handleCanPlay} style={style} />

            <canvas ref={canvasRef} style={{ display: "none" }} />
            {/* <Canvas
              ref={canvasRef}
              width={container.width}
              height={container.height}
              widthDpi={container.width * dpi}
              heightDpi={container.height * dpi}
            /> */}

            {/* <Flash flash={isFlashing} onAnimationEnd={() => setIsFlashing(false)} /> */}
          </Container>

          <button
            style={{
              position: "absolute",
              bottom: "30px",
              padding: "15px 30px",
              fontSize: "18px",
              background: "#fff",
              color: "#000",
              border: "none",
              borderRadius: "30px",
              cursor: "pointer",
            }}
            onClick={capturePhoto}
          >
            Take Photo
          </button>

          {/* <Stack direction={"row"} mt={2} spacing={2}>
            {isCanvasEmpty ? (
              <>
                <IconButton onClick={toggleCamera}>
                  <CameraSwitchIcon />
                </IconButton>
                <IconButton onClick={handleCapture}>
                  <CameraAltIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
                <IconButton onClick={handleConfirm}>
                  <CheckIcon />
                </IconButton>
              </>
            )}
          </Stack> */}
        </Wrapper>
      )}
    </Measure>
  );
};

export default Camera;
