import React, { forwardRef } from "react";
import "./styles.css";

const Video = forwardRef((props, ref) => {
  const { hidden, onCanPlay, style } = props;
  return (
    <div className="camera-container">
      <video ref={ref} hidden={hidden} onCanPlay={onCanPlay} autoPlay playsInline muted style={style} />
      <div className="card-frame">
        <div className="frame-corner top-left"></div>
        <div className="frame-corner top-right"></div>
        <div className="frame-corner bottom-left"></div>
        <div className="frame-corner bottom-right"></div>
      </div>
    </div>
  );
});

export default Video;
