import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Success = (props) => {
  const { onStartAgain } = props;

  return (
    <Grid container justifyContent={"center"} sx={{ height: "100vh" }} alignContent={"center"}>
      <Grid item xs={12} md={4}>
        <Box textAlign={"center"}>
          <CheckCircleIcon color="success" style={{ fontSize: 64 }} />
          <Typography fontSize={18}>Verification Successful</Typography>
        </Box>

        <Box textAlign={"center"} my={4}>
          <Button onClick={onStartAgain}>Start Again</Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Success;
