import { useCallback, useContext } from "react";
import { AuthContext } from "../../App";
import { SERVER_HOST } from "../../constants/config";

const BaseAPIUrl = SERVER_HOST;

const useAdminService = () => {
  const { authState } = useContext(AuthContext);

  const findValidationRecord = useCallback(
    async (query = {}) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
      };

      return fetch(
        BaseAPIUrl +
          "/admin/dashboard/findValidationRecord?name=" +
          query.name +
          "&offset=" +
          query.offset +
          "&limit=" +
          query.limit,
        config
      ).then((response) => response.json());
    },
    [authState]
  );

  return {
    findValidationRecord,
  };
};

export { useAdminService };
