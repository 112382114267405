import React, { useState, useEffect, useContext, useCallback } from "react";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { EKYCContext } from "../EKYC3";
import { useFaceLivenessService } from "../services/useFaceLivenessService";
import bytesToFile from "../../../utils/bytesToFile";
import base64ToFile from "../../../utils/base64ToFile";

const VerifyFaceLiveness = (props) => {
  const { onNext } = props;

  const { id, verifyMyKadResult, setFaceLivenessResult } = useContext(EKYCContext);

  const { createStreamSession, getLivenessSessionResult, compareMyKadProfileWithLivePerson } = useFaceLivenessService();

  const [faceLivenessSessionId, setFaceLivenessSessionId] = useState(null);
  const [referenceImageFile, setReferenceImageFile] = useState(null);
  const [auditImageFileArray, setAuditImageFileArray] = useState([]);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submittingMessage, setSubmittingMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [myKadProfileAndLivePersonResult, setMyKadProfileAndLivePersonResult] = useState(null);

  useEffect(() => {
    if (!faceLivenessSessionId) {
      onFaceLivenessStart();
    }

    return () => {
      if (faceLivenessSessionId) {
        // Clean up
        setFaceLivenessSessionId(null);
      }
    };
  }, [faceLivenessSessionId]);

  const onFaceLivenessStart = async () => {
    setLoading(true);

    try {
      const res = await createStreamSession();
      if (res.status === "success") {
        setFaceLivenessSessionId(res.data.SessionId);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const CustomError = useCallback(() => {
    return (
      <Box flex={1}>
        <Alert
          severity="error"
          action={
            <Button variant="contained" onClick={reset} size="small">
              Try Again
            </Button>
          }
        >
          <Typography>{error?.error.message}</Typography>
        </Alert>
      </Box>
    );
  }, [error]);

  const reset = () => {
    setFaceLivenessSessionId(null);
  };

  const onAnalysisComplete = async (sessionId) => {
    try {
      setSubmitting(true);
      setSubmittingMessage("Analyzing...");
      const res = await getLivenessSessionResult({
        id: id,
        sessionId: sessionId,
      });
      if (res.status === "success") {
        setFaceLivenessResult(res.data);
        setReferenceImageFile(bytesToFile(res.data.ReferenceImage.Bytes));
        setAuditImageFileArray(res.data.AuditImages.map((auditImage) => bytesToFile(auditImage.Bytes)));

        let refImage = bytesToFile(res.data.ReferenceImage.Bytes);
        onCompareFaces(refImage);
      } else {
        setErrorMessage(res.message);
      }
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  const onCompareFaces = async (refImage) => {
    setSubmitting(true);
    setSubmittingMessage("Comparing faces...");

    const myKadProfilePhoto = base64ToFile(verifyMyKadResult["MyKadFront"]["ColoredProfilePhoto"]["base64"]);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("myKadProfilePhoto", myKadProfilePhoto);
    // formData.append("livePersonPhoto", referenceImageFile);
    formData.append("livePersonPhoto", refImage);

    const res = await compareMyKadProfileWithLivePerson(formData);

    if (res.status === "success") {
      setMyKadProfileAndLivePersonResult(res.data);
      onNext();
    } else {
      setErrorMessage(res.message);
    }

    setSubmitting(false);
  };

  if (loading) {
    return (
      <Box textAlign={"center"}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Backdrop
        open={submitting}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Stack alignItems={"center"} justifyContent={"center"}>
          <CircularProgress color="inherit" />
          <Typography variant="h6" color={"inherit"}>
            {submittingMessage}
          </Typography>
        </Stack>
      </Backdrop>

      {errorMessage ? (
        <Box p={4}>
          <Box textAlign={"center"}>
            <CancelIcon color="error" style={{ fontSize: 64 }} />
            <Typography>Failed to verify face liveness</Typography>
            <Typography variant="body1">{errorMessage}</Typography>
          </Box>

          <Box textAlign={"center"} mt={8}>
            <Button
              variant="contained"
              onClick={() => {
                setErrorMessage("");
                setFaceLivenessSessionId(null);
              }}
            >
              Try Again
            </Button>
          </Box>
        </Box>
      ) : (
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" textAlign={"center"} my={4}>
              Record a short video of yourself
            </Typography>

            <FaceLivenessDetector
              disableStartScreen={true}
              sessionId={faceLivenessSessionId}
              region="ap-northeast-1"
              onAnalysisComplete={() => onAnalysisComplete(faceLivenessSessionId)}
              onError={(error) => setError(error)}
              onUserCancel={() => setFaceLivenessSessionId()}
              components={{
                PhotosensitiveWarning: () => (
                  <Alert severity="warning">
                    This check displays colored lights. Use caution if you are photosensitive.
                  </Alert>
                ),
                ErrorView: CustomError,
              }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default VerifyFaceLiveness;
