export default function base64ToFile(base64) {
  // Check if base64 containes mime type. If yes, split the base64 string and get the mime type
  // If no, set the mime type to image/jpeg
  let byteString = null;
  let mimeString = null;

  if (base64.indexOf("data:") > -1) {
    const split = base64.split(",");
    byteString = atob(split[1]);
    mimeString = split[0].split(":")[1].split(";")[0];
  } else {
    byteString = atob(base64);
    mimeString = "image/jpeg";
  }

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const file = new Blob([ab], { type: mimeString });
  return file;
}

/**
 * Example usage of Blob:
 *
 * Example 1:
 * const formData = new FormData();
 * formData.append("file", blob);
 *
 * Example 2:
 * <img src={URL.createObjectURL(blob))} />
 *
 */
