import React, { useState, useEffect } from "react";
import "./App.css";
import ThemeRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsexports from "./aws-exports";
import { useLoginService } from "./modules/login/useLoginService";
Amplify.configure(awsexports);

export const AuthContext = React.createContext();

function App() {
  const { checkAuth } = useLoginService();

  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    token: null,
  });

  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   checkAuth().then((res) => {
  //     if (res.status === "success") {
  //       setAuthState({
  //         isAuthenticated: true,
  //         token: res.data.token,
  //       });
  //     }
  //     setLoading(false);
  //   });
  // }, [loading]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
      }}
    >
      {/* {loading ? (
        <div>Loading...</div>
      ) : ( */}
      <BrowserRouter>
        <ThemeRoutes />
      </BrowserRouter>
      {/* )} */}
    </AuthContext.Provider>
  );
}

export default App;
