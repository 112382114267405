import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

const Guideline = (props) => {
  const { onNext } = props;

  const [loading, setLoading] = useState(false);

  return (
    <Grid container justifyContent={"center"} p={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="h6" textAlign={"center"} my={2}>
          Take a photo of your MyKad
        </Typography>

        <Stack>
          <Typography variant="h5" color={"green"}>
            Do's
          </Typography>

          <ul>
            <li>Clear and readable - no glares, reflection and blurry.</li>
            <li>Make sure the corners are captured.</li>
            <li>Recommend to place MyKad on a plain colored background.</li>
          </ul>
        </Stack>

        <Stack my={4}>
          <Typography variant="h5" color={"red"}>
            Don't
          </Typography>

          <ul>
            <li>No glares, reflection and blurry.</li>
            <li>No incorrect cropping and scaling.</li>
          </ul>
        </Stack>

        <Box textAlign={"center"}>
          <LoadingButton loading={loading} onClick={onNext} variant="contained">
            <span>Next</span>
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Guideline;
