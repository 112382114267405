import React, { useState, useContext, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import CancelIcon from "@mui/icons-material/Cancel";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { EKYCContext } from "../EKYC3";
import { AspectRatio } from "react-aspect-ratio";
import { useMyKadService } from "../services/useMyKadService";
import base64ToFile from "../../../utils/base64ToFile";

const detectCardSuggestionList = [
  "Ensure all 4 corners of the card are visible.",
  "Background with good contrast to the card.",
  "Use landscape mode for better results. Make sure the card covers large portion of the screen.",
];

const VerifyMyKad = (props) => {
  const { onNext } = props;

  const { id, setVerifyMyKadResult } = useContext(EKYCContext);

  const {
    verifyMyKad3,
    perspectiveCorrection,
    // detectMyKadFrontUserData,
    // detectMyKadBackUserData
  } = useMyKadService();

  const myKadFrontInputRef = useRef(null);
  const myKadBackInputRef = useRef(null);

  const [myKadFrontBase64, setMyKadFrontBase64] = useState(null);
  const [myKadBackBase64, setMyKadBackBase64] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submittingMessage, setSubmittingMessage] = useState("Uploading...");
  const [errorMessage, setErrorMessage] = useState(null);
  // const [myKadDetectedICNumber, setMyKadDetectedICNumber] = useState("");
  // const [myKadDetectedName, setMyKadDetectedName] = useState("");
  // const [myKadDetectedAddress, setMyKadDetectedAddress] = useState("");
  // const [myKadBackDetectedICNumber, setMyKadBackDetectedICNumber] = useState("");

  const [showPossibleFailReason, setShowPossibleFailReason] = useState(false);
  const [possibleFailReasonList, setPossibleFailReasonList] = useState([]);

  const [showSuggestionListFront, setShowSuggestionListFront] = useState(false);
  const [showSuggestionListBack, setShowSuggestionListBack] = useState(false);

  const onImageLoaded = async (imageFile, myKadSide) => {
    // Invoke image perspective correction.
    setSubmitting(true);
    setSubmittingMessage("Processing image...");

    const formData = new FormData();
    formData.append("imageFile", imageFile);
    formData.append("id", id);
    formData.append("myKadSide", myKadSide);

    let transformedBase64 = null;
    let res = await perspectiveCorrection(formData);
    // alert("On Image Loaded: " + res.status);
    if (res.status === "success") {
      transformedBase64 = res.data.transformedBase64;
      setShowSuggestionListFront(false);
      setShowSuggestionListBack(false);
    } else {
      setErrorMessage(res.message);
      if (myKadSide === "front") {
        setShowSuggestionListFront(true);
      } else {
        setShowSuggestionListBack(true);
      }
      alert("Unable to detect card. Please try again.");
    }

    setSubmitting(false);
    return transformedBase64;
  };

  const onSubmit = () => {
    setSubmitting(true);
    setSubmittingMessage("Verifying MyKad...");

    const myKadFrontFile = base64ToFile(myKadFrontBase64);
    const myKadBackFile = base64ToFile(myKadBackBase64);

    const formData = new FormData();
    formData.append("id", id);
    formData.append("myKadFront", myKadFrontFile);
    formData.append("myKadBack", myKadBackFile);

    verifyMyKad3(formData)
      .then((response) => {
        if (response.status === "success") {
          setVerifyMyKadResult(response.data);
          onNext();
        } else {
          setShowPossibleFailReason(true);
          setErrorMessage(response.message);
          setPossibleFailReasonList(response.data.FailReasonList);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
      });
  };

  return (
    <Box>
      <Backdrop
        open={submitting}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Stack alignItems={"center"} justifyContent={"center"}>
          <CircularProgress color="inherit" />
          <Typography variant="h6" color={"inherit"}>
            {submittingMessage}
          </Typography>
        </Stack>
      </Backdrop>

      {showPossibleFailReason ? (
        <Box p={4}>
          <Box textAlign={"center"}>
            <CancelIcon color="error" style={{ fontSize: 64 }} />
            <Typography>Failed to verify MyKad</Typography>
            <Typography variant="body1">{errorMessage}</Typography>
          </Box>
          <Box px={2} mt={4}>
            <Typography>Possible Reasons: </Typography>

            <ul>
              {possibleFailReasonList.map((reason, index) => (
                <li key={index}>{reason}</li>
              ))}
            </ul>
          </Box>

          <Box textAlign={"center"} mt={8}>
            <Button
              variant="contained"
              onClick={() => {
                setMyKadFrontBase64(null);
                setMyKadBackBase64(null);
                setErrorMessage(null);
                setShowPossibleFailReason(false);
              }}
            >
              Try Again
            </Button>
          </Box>
        </Box>
      ) : (
        <Grid container justifyContent={"center"} p={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" textAlign={"center"} my={2}>
              Take a photo of the front and back of your MyKad
            </Typography>

            <Stack spacing={4} mb={4}>
              <Box>
                <ButtonBase
                  style={{
                    border: "1px solid #ccc",
                  }}
                  sx={{ width: "100%" }}
                  onClick={() => myKadFrontInputRef.current.click()}
                >
                  <AspectRatio
                    ratio={"9/16"}
                    style={{
                      maxHeight: "180px",
                      height: "180px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {myKadFrontBase64 ? (
                      <img
                        src={"data:image/jpeg;base64," + myKadFrontBase64}
                        alt="MyKad Front"
                        width={"auto"}
                        height={"180px"}
                      />
                    ) : (
                      <>
                        <PhotoCameraIcon fontSize={"large"} />
                        <Typography variant="body1">Tap to Capture Front MyKad</Typography>
                      </>
                    )}
                    <input
                      ref={myKadFrontInputRef}
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      capture="environment"
                      hidden
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        const base64 = await onImageLoaded(file, "front");
                        setMyKadFrontBase64(base64);
                        // await onMyKadFrontTransformed(base64ToFile(base64));
                      }}
                    />
                  </AspectRatio>
                </ButtonBase>

                {myKadFrontBase64 && (
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <Button variant="outlined" color="secondary" onClick={() => myKadFrontInputRef.current.click()}>
                      Retake
                    </Button>
                  </Box>
                )}
                {showSuggestionListFront && (
                  <Stack mt={1}>
                    <Typography variant="body1">Tips:</Typography>
                    {detectCardSuggestionList.map((suggestion, index) => (
                      <Typography key={index} variant="body2">
                        {index + 1}. {suggestion}
                      </Typography>
                    ))}
                  </Stack>
                )}
              </Box>
              <Box>
                <ButtonBase
                  style={{
                    border: "1px solid #ccc",
                  }}
                  sx={{ width: "100%" }}
                  onClick={() => myKadBackInputRef.current.click()}
                >
                  <AspectRatio
                    ratio={"9/16"}
                    style={{
                      height: "180px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {myKadBackBase64 ? (
                      <img
                        src={"data:image/jpeg;base64," + myKadBackBase64}
                        alt="MyKad Back"
                        width={"auto"}
                        height={"180px"}
                      />
                    ) : (
                      <>
                        <PhotoCameraIcon fontSize={"large"} />
                        <Typography variant="body1">Tap to Capture Back MyKad</Typography>
                      </>
                    )}

                    <input
                      ref={myKadBackInputRef}
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      capture="environment"
                      hidden
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        const base64 = await onImageLoaded(file, "back");
                        // await onMyKadBackTransformed(base64ToFile(base64));
                        setMyKadBackBase64(base64);
                      }}
                    />
                  </AspectRatio>
                </ButtonBase>
                {myKadBackBase64 && (
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <Button variant="outlined" color="secondary" onClick={() => myKadBackInputRef.current.click()}>
                      Retake
                    </Button>
                  </Box>
                )}
                {showSuggestionListBack && (
                  <Stack mt={1}>
                    <Typography variant="body1">Tips:</Typography>
                    {detectCardSuggestionList.map((suggestion, index) => (
                      <Typography key={index} variant="body2">
                        {index + 1}. {suggestion}
                      </Typography>
                    ))}
                  </Stack>
                )}
              </Box>
            </Stack>

            <LoadingButton
              variant="contained"
              onClick={onSubmit}
              loading={false}
              disabled={!myKadFrontBase64 || !myKadBackBase64}
            >
              <span>Submit</span>
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default VerifyMyKad;
