import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SERVER_HOST } from "../../../constants/config";
import PlaceholderImage from "../../../assets/images/placeholder.jpg";

const MyKadDialog = (props) => {
  const { viewItem, visible, onClose } = props;

  const [isMyKadFront, setIsMyKadFront] = useState({});
  const [isMyKadBack, setIsMyKadBack] = useState({});
  const [myKadFrontDetectedICNumberList, setMyKadFrontDetectedICNumberList] = useState([]);
  const [myKadFrontDetectedNameList, setMyKadFrontDetectedNameList] = useState([]);
  const [myKadBackDetectedICNumberList, setMyKadBackDetectedICNumberList] = useState([]);
  const [myKadFrontUserUpload, setMyKadFrontUserUpload] = useState({});
  const [myKadBackUserUpload, setMyKadBackUserUpload] = useState({});
  const [myKadFrontImage, setMyKadFrontImage] = useState({});
  const [myKadFrontICRegionImage, setMyKadFrontICRegionImage] = useState({});
  const [myKadFrontNameRegionImage, setMyKadFrontNameRegionImage] = useState({});
  const [myKadBackImage, setMyKadBackImage] = useState({});
  const [myKadBackICRegionImage, setMyKadBackICRegionImage] = useState({});
  const [microPrintImage, setMicroPrintImage] = useState({});
  const [microPrintDetectedList, setMicroPrintDetectedList] = useState([]);
  const [verificationResult, setVerificationResult] = useState({});

  useEffect(() => {
    if (visible && JSON.stringify(viewItem) !== "{}") {
      if (viewItem["ValidationRecord/MyKadFrontImage"]) {
        setMyKadFrontImage(JSON.parse(viewItem["ValidationRecord/MyKadFrontImage"]));
      }
      if (viewItem["ValidationRecord/IsMyKadFront"]) {
        setIsMyKadFront(JSON.parse(viewItem["ValidationRecord/IsMyKadFront"]));
      }

      if (viewItem["ValidationRecord/IsMyKadBack"]) {
        setIsMyKadBack(JSON.parse(viewItem["ValidationRecord/IsMyKadBack"]));
      }
      if (viewItem["ValidationRecord/DetectedICNumberList"]) {
        setMyKadFrontDetectedICNumberList(viewItem["ValidationRecord/DetectedICNumberList"]);
      }
      if (viewItem["ValidationRecord/DetectedNameList"]) {
        setMyKadFrontDetectedNameList(viewItem["ValidationRecord/DetectedNameList"]);
      }
      if (viewItem["ValidationRecord/MyKadBackImage"]) {
        setMyKadBackImage(JSON.parse(viewItem["ValidationRecord/MyKadBackImage"]));
      }
      if (viewItem["ValidationRecord/DetectedBackICNumberList"]) {
        setMyKadBackDetectedICNumberList(viewItem["ValidationRecord/DetectedBackICNumberList"]);
      }
      if (viewItem["ValidationRecord/MyKadVerificationResult"]) {
        setVerificationResult(JSON.parse(viewItem["ValidationRecord/MyKadVerificationResult"]));
      }
      if (viewItem["ValidationRecord/MyKadFrontUserUpload"]) {
        setMyKadFrontUserUpload(JSON.parse(viewItem["ValidationRecord/MyKadFrontUserUpload"]));
      }
      if (viewItem["ValidationRecord/MyKadBackUserUpload"]) {
        setMyKadBackUserUpload(JSON.parse(viewItem["ValidationRecord/MyKadBackUserUpload"]));
      }
      if (viewItem["ValidationRecord/MyKadFrontICRegionImage"]) {
        setMyKadFrontICRegionImage(JSON.parse(viewItem["ValidationRecord/MyKadFrontICRegionImage"]));
      }
      if (viewItem["ValidationRecord/MyKadFrontNameRegionImage"]) {
        setMyKadFrontNameRegionImage(JSON.parse(viewItem["ValidationRecord/MyKadFrontNameRegionImage"]));
      }
      if (viewItem["ValidationRecord/MyKadBackICRegionImage"]) {
        setMyKadBackICRegionImage(JSON.parse(viewItem["ValidationRecord/MyKadBackICRegionImage"]));
      }
      if (viewItem["ValidationRecord/MicroPrintImage"]) {
        setMicroPrintImage(JSON.parse(viewItem["ValidationRecord/MicroPrintImage"]));
      }
      if (viewItem["ValidationRecord/MicroPrintDetectedList"]) {
        setMicroPrintDetectedList(viewItem["ValidationRecord/MicroPrintDetectedList"]);
      }
    }
  }, [viewItem, visible]);

  useEffect(() => {
    if (!visible) {
      setMyKadFrontUserUpload({});
      setMyKadFrontImage({});
      setIsMyKadFront({});
      setIsMyKadBack({});
      setMyKadBackUserUpload({});
      setMyKadBackImage({});
      setVerificationResult({});
      setMyKadBackDetectedICNumberList([]);
      setMyKadFrontDetectedICNumberList([]);
      setMyKadFrontDetectedNameList([]);
      setMyKadFrontICRegionImage({});
      setMyKadFrontNameRegionImage({});
      setMyKadBackICRegionImage({});
      setMicroPrintImage({});
      setMicroPrintDetectedList([]);
    }
  }, [visible]);

  return (
    <Dialog open={visible} onClose={onClose} fullWidth>
      <DialogTitle>
        MyKad Verification{" "}
        {verificationResult["Result"] === "Pass" ? (
          <Chip label="Pass" color="success" />
        ) : (
          <Chip label="Fail" color="error" />
        )}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ backgroundColor: "#efefef" }} px={2} mb={2}>
          <Typography variant="overline">MyKad Front</Typography>
        </Box>

        <Stack spacing={4}>
          <Box>
            <Typography variant="subtitle">1. Original & Cropped Image Uploaded by User</Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${myKadFrontUserUpload.Key}`}
                    alt="Original MyKad Front Uploaded by User"
                    style={{ width: "100%" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${myKadFrontImage.Key}`}
                    alt="Cropped MyKad Front Image Uploaded by User"
                    style={{ width: "100%" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography variant="subtitle">2. Is Front of MyKad?</Typography>
            <Box p={2}>
              <img
                src={`${SERVER_HOST}/storages/${isMyKadFront.ImageKey}`}
                alt="Cropped MyKad Front Image Uploaded by User"
                style={{ width: "100%", maxWidth: "180px" }}
                onError={(e) => {
                  e.target.src = PlaceholderImage;
                }}
              />
              <Typography variant="body2">Confidence: {isMyKadFront.Confidence}%</Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle">3. MyKad Number & Name</Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${myKadFrontICRegionImage.Key}`}
                    alt="IC Region of MyKad Front"
                    style={{ width: "100%", maxWidth: "180px" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                  <Typography variant="body2">
                    {myKadFrontDetectedICNumberList
                      .map((item) => JSON.parse(item))
                      .map((item) => item["DetectedText"])
                      .join(", ")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${myKadFrontNameRegionImage.Key}`}
                    alt="Name Region of MyKad Front"
                    style={{ width: "100%", maxWidth: "180px" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                  <Typography variant="body2">
                    {myKadFrontDetectedNameList
                      .map((item) => JSON.parse(item))
                      .map((item) => item["DetectedText"])
                      .join(", ")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography variant="subtitle">4. Landmarks. Check Possible Tampering.</Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${verificationResult["KadPengenalanLabel"]?.["Image"]}`}
                    alt="Kad Pengenalan"
                    style={{ width: "180px", height: "auto", maxWidth: "180px" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                  <Typography variant="body2">
                    Kad Pengenalan: {verificationResult["KadPengenalanLabel"]?.["Confidence"]}%
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${verificationResult["MyKadLogo"]?.["Image"]}`}
                    alt="MyKad Logo"
                    style={{ width: "180px", height: "auto", maxWidth: "180px" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                  <Typography variant="body2">
                    MyKad Logo: {verificationResult["MyKadLogo"]?.["Confidence"]}%
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${verificationResult["MalaysiaFlag"]?.["Image"]}`}
                    alt="Malaysia Flag"
                    style={{ width: "180px", height: "auto", maxWidth: "180px" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                  <Typography variant="body2">
                    Malaysia Flag: {verificationResult["MalaysiaFlag"]?.["Confidence"]}%
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${verificationResult["IDCardChip"]?.["Image"]}`}
                    alt="IDCardChip"
                    style={{ width: "180px", height: "auto", maxWidth: "180px" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                  <Typography variant="body2">
                    ID Card Chip: {verificationResult["IDCardChip"]?.["Confidence"]}%
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${verificationResult["GhostProfilePhoto"]?.["Image"]}`}
                    alt="B/W Profile"
                    style={{ width: "180px", height: "auto", maxWidth: "180px" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                  <Typography variant="body2">
                    B/W Profile:{" "}
                    {verificationResult["GhostProfilePhoto"] &&
                    verificationResult["GhostProfilePhoto"]["FaceCompareResult"] &&
                    verificationResult["GhostProfilePhoto"]["FaceCompareResult"].length > 0
                      ? Number(
                          verificationResult["GhostProfilePhoto"]["FaceCompareResult"][0]["Similarity"]
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : 0}
                    % Similar to Profile
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${verificationResult["ColoredProfilePhoto"]?.["Image"]}`}
                    alt="ColoredProfilePhoto"
                    style={{ width: "180px", height: "auto", maxWidth: "180px" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                  <Typography variant="body2">
                    Profile Photo: {verificationResult["ColoredProfilePhoto"]?.["Confidence"]}%
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${microPrintImage.Key}`}
                    alt="MicroPrint"
                    style={{ width: "180px", height: "auto", maxWidth: "180px" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                  <Typography variant="body2">
                    MicroPrint: {verificationResult["MicroPrint"]?.["Detected"] ? "Yes" : "No"}
                  </Typography>

                  <Typography variant="body2">
                    Detected List:{" "}
                    {microPrintDetectedList
                      .map((item) => JSON.parse(item))
                      .map((item) => item["DetectedText"])
                      .join(", ")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack>

        <Box sx={{ backgroundColor: "#efefef" }} px={2} my={2}>
          <Typography variant="overline">MyKad Back</Typography>
        </Box>

        <Stack spacing={4}>
          <Box>
            <Typography variant="subtitle">1. Original & Cropped Image Uploaded by User</Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${myKadBackUserUpload.Key}`}
                    alt="MyKad Back Uploaded by User"
                    style={{ width: "100%" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${myKadBackImage.Key}`}
                    alt="Cropped MyKad Back Image Uploaded by User"
                    style={{ width: "100%" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography variant="subtitle">2. Is Back of MyKad?</Typography>
            <Box p={2}>
              <img
                src={`${SERVER_HOST}/storages/${isMyKadBack.ImageKey}`}
                alt="Cropped MyKad Back Image Uploaded by User"
                style={{ width: "100%", maxWidth: "180px" }}
                onError={(e) => {
                  e.target.src = PlaceholderImage;
                }}
              />
              <Typography variant="body2">Confidence: {isMyKadBack.Confidence}%</Typography>
            </Box>
          </Box>

          <Box>
            <Typography variant="subtitle">3. MyKad Number</Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <img
                    src={`${SERVER_HOST}/storages/${myKadBackICRegionImage.Key}`}
                    alt="IC Region of MyKad Back"
                    style={{ width: "100%", maxWidth: "180px" }}
                    onError={(e) => {
                      e.target.src = PlaceholderImage;
                    }}
                  />
                  <Typography variant="body2">
                    {myKadBackDetectedICNumberList
                      .map((item) => JSON.parse(item))
                      .map((item) => item["DetectedText"])
                      .join(", ")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography variant="subtitle">4. Landmarks. Check Possible Tampering.</Typography>
            <Grid item xs={12} md={6}>
              <Box p={2}>
                <img
                  src={`${SERVER_HOST}/storages/${verificationResult["MalaysiaEmblem"]?.["Image"]}`}
                  alt="MalaysiaEmblem"
                  style={{ width: "180px", height: "auto", maxWidth: "180px" }}
                  onError={(e) => {
                    e.target.src = PlaceholderImage;
                  }}
                />
                <Typography variant="body2">Emblem: {verificationResult["MalaysiaEmblem"]?.["Confidence"]}%</Typography>
              </Box>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MyKadDialog;
