import React, { forwardRef } from "react";

const Container = forwardRef((props, ref) => {
  const { maxWidth, maxHeight, style } = props;

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
        width: "100%",
        maxWidth: `${maxWidth}px`,
        maxHeight: `${maxHeight}px`,
        overflow: "hidden",
        ...style,
      }}
    >
      {props.children}
    </div>
  );
});

export default Container;
