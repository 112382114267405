import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SERVER_HOST } from "../../../constants/config";
import PlaceholderImage from "../../../assets/images/placeholder.jpg";

const FaceLivenessDialog = (props) => {
  const { viewItem, visible, onClose } = props;

  const [referenceImage, setReferenceImage] = useState({});
  const [auditImageList, setAuditImageList] = useState([]);
  const [verificationResult, setVerificationResult] = useState({});
  const [compareMyKadResult, setCompareMyKadResult] = useState({});

  useEffect(() => {
    if (JSON.stringify(viewItem) !== "{}") {
      if (viewItem["ValidationRecord/FaceLivenessResult"]) {
        setVerificationResult(JSON.parse(viewItem["ValidationRecord/FaceLivenessResult"]));
      }
      if (viewItem["ValidationRecord/FaceLivenessReferenceImage"]) {
        setReferenceImage(JSON.parse(viewItem["ValidationRecord/FaceLivenessReferenceImage"]));
      }
      if (viewItem["ValidationRecord/FaceLivenessAuditImageList"]) {
        setAuditImageList(viewItem["ValidationRecord/FaceLivenessAuditImageList"].map((item) => JSON.parse(item)));
      }
      if (viewItem["ValidationRecord/MyKadProfileWithLivePersonCompareResult"]) {
        setCompareMyKadResult(JSON.parse(viewItem["ValidationRecord/MyKadProfileWithLivePersonCompareResult"]));
      }
    }
  }, [viewItem]);

  useEffect(() => {
    if (!visible) {
      setReferenceImage({});
      setAuditImageList([]);
      setVerificationResult({});
      setCompareMyKadResult({});
    }
  }, [visible]);

  return (
    <Dialog open={visible} onClose={onClose} fullWidth>
      <DialogTitle>Face Liveness Verification</DialogTitle>
      <DialogContent>
        <Box sx={{ backgroundColor: "#efefef" }} px={2}>
          <Typography variant="overline">Reference Image & Confidence</Typography>
        </Box>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Box p={2}>
              <img
                src={`${SERVER_HOST}/storages/${referenceImage.Key}`}
                alt="MyKad Front"
                style={{ width: "100%" }}
                onError={(e) => {
                  e.target.src = PlaceholderImage;
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="column">
              <ListItem>
                <ListItemText
                  primary="Confidence"
                  secondary={Number(verificationResult["Confidence"]).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Session ID" secondary={verificationResult["SessionId"]} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Status" secondary={verificationResult["Status"]} />
              </ListItem>
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ backgroundColor: "#efefef" }} px={2}>
          <Typography variant="overline">Audit Images</Typography>
        </Box>

        <Grid container>
          {auditImageList.map((item, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Box p={2}>
                <img
                  src={`${SERVER_HOST}/storages/${item.Key}`}
                  alt="Audit Image"
                  style={{ width: "100%" }}
                  onError={(e) => {
                    e.target.src = PlaceholderImage;
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ backgroundColor: "#efefef" }} px={2}>
          <Typography variant="overline">MyKad Profile Comparison</Typography>
        </Box>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Box p={2}>
              <img
                src={`${SERVER_HOST}/storages/${compareMyKadResult["sourceImageKey"]}`}
                alt="MyKad Front"
                style={{ width: "100%" }}
                onError={(e) => {
                  e.target.src = PlaceholderImage;
                }}
              />
              {compareMyKadResult["SourceImageFace"]?.Confidence}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={`${SERVER_HOST}/storages/${compareMyKadResult["targetImageKey"]}`}
              alt="MyKad Front"
              style={{ width: "100%" }}
            />
            <Stack direction="column">
              {compareMyKadResult["FaceMatches"]?.map((item, index) => (
                <Box p={2} key={index}>
                  {item.Similarity}
                </Box>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FaceLivenessDialog;
