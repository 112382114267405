import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import TextField from "@mui/material/TextField";
import { EKYCContext } from "../EKYC3";
import { useEKYCService } from "../services/useEKYCService";

const schema = object().shape({
  name: string()
    // no special characters allowed
    .matches(/^[a-zA-Z\s]*$/, "Only alphabets and spaces are allowed")
    .required("Name is required."),
  myKadNumber: string()
    .matches(/^\d{12}$/, "The MyKad number must be 12 digits long")
    // The first two digits are the birth year, so it must be between 00 and 99
    .matches(/^[0-9]{2}/, "Invalid MyKad number")
    // The 3rd and 4th digits are the birth month, so it must be between 01 and 12
    .matches(/^\d{2}(0[1-9]|1[0-2])/, "Invalid MyKad number")
    // The next two digits are the birth day, so it must be between 01 and 31
    .matches(/^\d{4}(0[1-9]|[12][0-9]|3[01])/, "Invalid MyKad number")
    // The next two digits are the birth place code, so it must be between 01 to 16, 21 to 58, or 82
    .matches(/^\d{6}(0[1-9]|1[0-6]|2[1-9]|[3-5][0-8]|82)/, "Invalid MyKad number")
    // The last 4 digits  must be between 0001 and 9999
    // .matches(/^\d{10}(000[1-9]|00[1-9][0-9]|0[1-9][0-9]{2}|[1-9][0-9]{3})$/, "Invalid MyKad number")
    // .matches(/^\d{6}-\d{2}-\d{4}$/, "The input must be in the format xxxxxx-xx-xxxx")
    .required("This field is required"),
});

const EnterData = (props) => {
  const { onNext } = props;

  const { setId } = useContext(EKYCContext);

  const { startEkyc } = useEKYCService();

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onStart = async (formData) => {
    setLoading(true);

    try {
      const res = await startEkyc({
        name: formData.name,
        myKadNumber: formData.myKadNumber,
      });
      if (res.status === "success") {
        setId(res.data.tempids["ValidationRecord"][0]);
        onNext();
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Grid container justifyContent={"center"} p={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="h6" textAlign={"center"} my={2}>
          Welcome to the eKYC demo app
        </Typography>

        <Typography variant="body1" textAlign={"center"} my={2}>
          To begin, please enter your name and MyKad number.
        </Typography>

        <Grid container spacing={2} mt={8}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <TextField
                  value={value}
                  onChange={(e) => {
                    // if e.target.value is special character, it will not be set to the input
                    if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                      onChange(e.target.value.toUpperCase());
                    }
                    // const upperCase = e.target.value.toUpperCase();
                    // onChange(upperCase);
                  }}
                  label="Full name as per MyKad"
                  fullWidth
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="myKadNumber"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <TextField
                  value={value}
                  onChange={(e) => {
                    // only allow numbers
                    if (/^\d*$/.test(e.target.value)) {
                      onChange(e.target.value);
                    }
                  }}
                  label="MyKad No. (without dashes)"
                  fullWidth
                  error={!!errors.myKadNumber}
                  helperText={errors.myKadNumber?.message}
                  type="tel"
                  inputProps={{ maxLength: 12 }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Box textAlign={"center"} mt={4}>
          <LoadingButton loading={loading} onClick={handleSubmit((data) => onStart(data))} variant="contained">
            <span>Start</span>
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EnterData;
