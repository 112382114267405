import { useCallback, useContext } from "react";
import { AuthContext } from "../../../App";

const BaseAPIUrl = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : "http://localhost:4000";

const useMyKadService = () => {
  const { authState } = useContext(AuthContext);

  const perspectiveCorrection = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        body: params,
      };

      return fetch(BaseAPIUrl + "/myKad/perspectiveCorrection", config).then((response) => response.json());
    },
    [authState]
  );

  // const detectMyKadFrontUserData = useCallback(
  //   async (params) => {
  //     const config = {
  //       method: "post",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //       },
  //       body: params,
  //     };

  //     return fetch(BaseAPIUrl + "/myKad/detectMyKadFrontUserData", config).then((response) => response.json());
  //   },
  //   [authState]
  // );

  // const detectMyKadBackUserData = useCallback(
  //   async (params) => {
  //     const config = {
  //       method: "post",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //       },
  //       body: params,
  //     };

  //     return fetch(BaseAPIUrl + "/myKad/detectMyKadBackUserData", config).then((response) => response.json());
  //   },
  //   [authState]
  // );

  const verifyMyKad3 = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        method: "post",
        body: params,
      };

      return fetch(BaseAPIUrl + "/myKad/verifyMyKad3", config).then((response) => response.json());
    },
    [authState]
  );

  return {
    perspectiveCorrection,
    // detectMyKadFrontUserData,
    // detectMyKadBackUserData,
    verifyMyKad3,
  };
};

export { useMyKadService };
