import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useAdminService } from "./useAdminService";
import { format } from "date-fns";
import { IconButton, Input, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MyKadDialog from "./components/MyKadDialog";
import { useLoginService } from "../login/useLoginService";
import FaceLivenessDialog from "./components/FaceLivenessDialog";
import LoadingButton from "@mui/lab/LoadingButton";

const Dashboard = () => {
  const navigate = useNavigate();
  const { logout } = useLoginService();
  const { findValidationRecord } = useAdminService();

  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [myKadDialog, setMyKadDialog] = useState(false);
  const [faceLivessDialog, setFaceLivessDialog] = useState(false);
  const [viewItem, setViewItem] = useState({});

  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(20);

  useEffect(() => {
    if (refresh) {
      findValidationRecord({
        name: searchName,
        offset: 1,
        limit: limit,
      }).then((response) => {
        if (response.status === "success") {
          setData(response.data);
        } else {
          setData([]);
        }
        setRefresh(false);
      });
    }
  }, [offset, refresh]);

  const loadMore = () => {
    const newOffset = offset + 1;
    setOffset(newOffset);

    setLoading(true);
    findValidationRecord({
      offset: newOffset,
      limit: limit,
    }).then((response) => {
      if (response.status === "success") {
        setData([...data, ...response.data]);
      }
      setLoading(false);
    });
  };

  const onSearch = () => {
    findValidationRecord({
      name: searchName,
      offset: 1,
      limit: limit,
    }).then((response) => {
      if (response.status === "success") {
        setData(response.data);
      } else {
        setData([]);
      }
    });
  };

  return (
    <Box bgcolor={"#efefef"}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Stack direction="row" spacing={2}>
            <IconButton
              onClick={() => {
                setRefresh(true);
              }}
              color="inherit"
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                logout();
                navigate("/", { replace: true });
              }}
              color="inherit"
            >
              <LogoutIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>

      <Box p={1} bgcolor={"white"}>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            fullWidth
            placeholder="Enter Name"
            value={searchName}
            onChange={(e) => {
              setSearchName(e.target.value);
            }}
          />
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={onSearch}>
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setSearchName("");
              }}
            >
              Clear
            </Button>
          </Stack>
        </Stack>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>MyKad No.</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">MyKad</TableCell>
              <TableCell align="right">Face Liveness</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row["_id"]} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row["_id"]}
                </TableCell>
                <TableCell>{format(new Date(row["ValidationRecord/StartTimestamp"]), "yyyy-MM-dd")}</TableCell>
                <TableCell>{format(new Date(row["ValidationRecord/StartTimestamp"]), "HH:mm:ss")}</TableCell>
                <TableCell>
                  {row["ValidationRecord/EndTimestamp"]
                    ? format(new Date(row["ValidationRecord/EndTimestamp"]), "HH:mm:ss")
                    : "-"}
                </TableCell>
                <TableCell>{row["ValidationRecord/Name"]}</TableCell>
                <TableCell>{row["ValidationRecord/MyKadNumber"]}</TableCell>
                <TableCell align="right">{row["ValidationRecord/Status"]}</TableCell>
                <TableCell align="right">
                  {row["ValidationRecord/MyKadFrontImage"] ? (
                    <Button
                      onClick={() => {
                        setViewItem(row);
                        setMyKadDialog(true);
                      }}
                    >
                      View
                    </Button>
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell align="right">
                  {row["ValidationRecord/FaceLivenessReferenceImage"] ? (
                    <Button
                      onClick={() => {
                        setViewItem(row);
                        setFaceLivessDialog(true);
                      }}
                    >
                      View
                    </Button>
                  ) : (
                    "-"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MyKadDialog
        viewItem={viewItem}
        visible={myKadDialog}
        onClose={() => {
          setMyKadDialog(false);
        }}
      />

      <Box textAlign={"center"}>
        <LoadingButton sx={{ my: 2 }} variant="outlined" onClick={loadMore} loading={loading} loadingPosition="end">
          <span>Load More</span>
        </LoadingButton>
      </Box>

      <FaceLivenessDialog
        viewItem={viewItem}
        visible={faceLivessDialog}
        onClose={() => {
          setFaceLivessDialog(false);
        }}
      />
    </Box>
  );
};

export default Dashboard;
