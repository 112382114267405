import { useCallback } from "react";
import { SECURITY_HOST } from "../../constants/config";

const BaseAPIUrl = SECURITY_HOST + "/wapi";

const useLoginService = () => {
  const login = useCallback(async (params) => {
    const config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
      credentials: "include",
    };

    return fetch(BaseAPIUrl + "/login", config).then((response) => response.json());
  }, []);

  const logout = useCallback(async (params) => {
    const config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
      credentials: "include",
    };

    return fetch(BaseAPIUrl + "/logout", config).then((response) => response.json());
  }, []);

  const checkAuth = useCallback(async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        FpId: "fpid",
      },
      credentials: "include",
    };
    return fetch(BaseAPIUrl + "/checkAuth", config).then((response) => response.json());
  }, []);

  return {
    login,
    logout,
    checkAuth,
  };
};

export { useLoginService };
