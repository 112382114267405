import React, { useState, useContext } from "react";

// material-ui
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../../App";
import { useForm } from "react-hook-form";
import { string, object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoginService } from "./useLoginService";
import { useNavigate } from "react-router-dom";

const schema = object().shape({
  username: string().required("Username is required."),
  password: string().required("Password is required"),
});

const Login = () => {
  const { setAuthState } = useContext(AuthContext);

  const navigate = useNavigate();

  const theme = useTheme();

  const { login } = useLoginService();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loginError, setLoginError] = useState("");

  const submit = (formData) => {
    login({ username: formData.username, password: formData.password }).then((res) => {
      if (res.status === "success") {
        setAuthState({
          isAuthenticated: true,
          token: res.data.token,
        });

        return navigate("/admin/dashboard");
      } else {
        setLoginError(res.message);
      }
    });
  };

  return (
    <Grid container component="main" style={{ minHeight: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        style={{
          backgroundImage: "url(https://source.unsplash.com/random?orientation=portrait&nature)",
          backgroundRepeat: "no-repeat",
          backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div
          style={{
            margin: theme.spacing(4, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{
              margin: theme.spacing(1),
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <form
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: theme.spacing(1),
            }}
            onSubmit={handleSubmit((data) => submit(data))}
          >
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              {...register("username")}
              error={!!errors.username}
              helperText={errors.username?.message}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              {...register("password")}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ margin: theme.spacing(3, 0, 2) }}
            >
              Sign In
            </Button>
          </form>
        </div>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!loginError}
        autoHideDuration={6000}
        onClose={() => setLoginError("")}
      >
        <Alert onClose={() => setLoginError("")} severity="error" sx={{ width: "100%" }}>
          {loginError}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Login;
