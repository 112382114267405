export default function bytesToFile(bytes) {
  const byteArray = new Uint8Array(Object.values(bytes));
  const blob = new Blob([byteArray], { type: "image/jpeg" });
  return blob;
}

/**
 * Example usage of Blob:
 *
 * Example 1:
 * const formData = new FormData();
 * formData.append("file", blob);
 *
 * Example 2:
 * <img src={URL.createObjectURL(blob))} />
 *
 */
