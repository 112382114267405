import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Dialog, Modal, Stack, Typography } from "@mui/material";
import "./TestCamera.css";
import Camera from "../../components/camera/Camera";
import { object } from "yup";

const TestCamera = () => {
  const [showCameraCover, setShowCameraCover] = useState(false);
  const [showCameraContain, setShowCameraContain] = useState(false);
  const [imageBlob, setImageBlob] = useState(null);
  const [base64Cover, setBase64Cover] = useState(null);
  const [base64Contain, setBase64Contain] = useState(null);

  return (
    <Box textAlign={"center"} p={2}>
      <Typography my={4} variant="h5">
        Test Camera
      </Typography>

      <Stack direction="column" spacing={2}>
        <Button variant="contained" color="primary" onClick={() => setShowCameraCover(true)}>
          Take Picture (Cover)
        </Button>
        <Box mt={4}>
          {base64Cover && <img src={base64Cover} alt="Captured" style={{ width: "100%", height: "auto" }} />}
        </Box>
      </Stack>

      <Stack direction="column" spacing={2} mt={8}>
        <Button variant="contained" color="primary" onClick={() => setShowCameraContain(true)}>
          Take Picture (Contain)
        </Button>
        <Box mt={4}>
          {base64Contain && <img src={base64Contain} alt="Captured" style={{ width: "100%", height: "auto" }} />}
        </Box>
      </Stack>

      <CameraDialogCover
        open={showCameraCover}
        onClose={() => setShowCameraCover(false)}
        onConfirm={(photoDataUrl) => {
          setBase64Cover(photoDataUrl);
          setShowCameraCover(false);
        }}
      />

      <CameraDialogContain
        open={showCameraContain}
        onClose={() => setShowCameraContain(false)}
        onConfirm={(photoDataUrl) => {
          setBase64Contain(photoDataUrl);
          setShowCameraContain(false);
        }}
      />
    </Box>
  );
};

const CameraDialogCover = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Camera
        onConfirm={onConfirm}
        style={{
          objectFit: "cover",
        }}
      />
    </Dialog>
  );
};

const CameraDialogContain = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Camera
        onConfirm={onConfirm}
        style={{
          objectFit: "contain",
        }}
      />
    </Dialog>
  );
};

const IDPhotoCapture = ({ visible, onCapture, onClose }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);

  useEffect(() => {
    const startCamera = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
        setStream(mediaStream);
        if (videoRef.current) {
          videoRef.current.srcObject = mediaStream;
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };

    startCamera();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const capturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const photoDataUrl = canvas.toDataURL("image/jpeg");
      onCapture(photoDataUrl);
    }
  };

  return (
    <Modal open={visible} onClose={onClose} sx={{ background: "transparent" }}>
      <div className="id-photo-capture">
        <div className="camera-container">
          <video ref={videoRef} autoPlay playsInline />
          <div className="card-frame">
            <div className="frame-corner top-left"></div>
            <div className="frame-corner top-right"></div>
            <div className="frame-corner bottom-left"></div>
            <div className="frame-corner bottom-right"></div>
          </div>
        </div>
        <button className="capture-button" onClick={capturePhoto}>
          Take Photo
        </button>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </div>
    </Modal>
  );
};

export default TestCamera;
