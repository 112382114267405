import { useCallback, useContext } from "react";
import { AuthContext } from "../../../App";

const BaseAPIUrl = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : "http://localhost:4000";

const useFaceLivenessService = () => {
  const { authState } = useContext(AuthContext);

  const compareFaces = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        method: "post",
        body: params,
      };

      return fetch(BaseAPIUrl + "/base/compareFaces", config).then((response) => response.json());
    },
    [authState]
  );

  const createStreamSession = useCallback(async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + authState.token,
      },
      method: "post",
    };

    return fetch(BaseAPIUrl + "/liveness/createStreamSession", config).then((response) => response.json());
  }, [authState]);

  const getLivenessSessionResult = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/liveness/getLivenessSessionResult", config).then((response) => response.json());
    },
    [authState]
  );

  const compareMyKadProfileWithLivePerson = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        method: "post",
        body: params,
      };

      return fetch(BaseAPIUrl + "/liveness/compareMyKadProfileWithLivePerson", config).then((response) =>
        response.json()
      );
    },
    [authState]
  );

  return {
    compareFaces,
    createStreamSession,
    getLivenessSessionResult,
    compareMyKadProfileWithLivePerson,
  };
};

export { useFaceLivenessService };
