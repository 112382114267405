import { useCallback, useContext } from "react";
import { AuthContext } from "../../../App";

const BaseAPIUrl = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : "http://localhost:4000";

const useEKYCService = () => {
  const { authState } = useContext(AuthContext);

  const startEkyc = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/ekyc/start", config).then((response) => response.json());
    },
    [authState]
  );

  return {
    startEkyc,
  };
};

export { useEKYCService };
